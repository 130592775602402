$font-root: '/assets/fonts/iosevka';

$families: (
    (iosevka, Iosevka),
    (iosevka-slab, Iosevka Slab),
);

$variants: (
    (thin,              Thin,               100,  null),
    (thinitalic,        Thin Italic,        100,  italic),
    (thinoblique,       Thin Oblique,       100,  oblique),
    (extralight,        Extralight,         200,  null),
    (extralightitalic,  Extralight Italic,  200,  italic),
    (extralightoblique, Extralight Oblique, 200,  oblique),
    (light,             Light,              300,  null),
    (lightitalic,       Light Italic,       300,  italic),
    (lightoblique,      Light Oblique,      300,  oblique),
    (regular,           Regular,            null, null),
    (italic,            Italic,             null, italic),
    (oblique,           Oblique,            null, oblique),
    (medium,            Medium,             500,  null),
    (mediumitalic,      Medium Italic,      500,  italic),
    (mediumoblique,     Medium Oblique,     500,  oblique),
    (bold,              Bold,               700,  null),
    (bolditalic,        Bold Italic,        700,  italic),
    (boldoblique,       Bold Oblique,       700,  oblique),
    (heavy,             Heavy,              900,  null),
    (heavyitalic,       Heavy Italic,       900,  italic),
    (heavyoblique,      Heavy Oblique,      900,  oblique),
);

@each $family, $family-pretty in $families {
    @each $variant, $variant-pretty, $weight, $style in $variants {
        @font-face {
            @if $weight {
                font-weight: $weight;
            }
            @if $style {
                font-style: $style;
            }
            font-family: $family-pretty;
            src:
            local('#{$family-pretty} #{$variant-pretty}'),
            url('#{$font-root}/#{$family}-#{$variant}.woff2') format('woff2'),
            url('#{$font-root}/#{$family}-#{$variant}.woff') format('woff'),
            url('#{$font-root}/#{$family}-#{$variant}.ttf') format('truetype');
        }
    }
}

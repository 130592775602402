// Copied from http://ethanschoonover.com/solarized

$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;

@mixin rebase($rebase03,$rebase02,$rebase01,$rebase00,$rebase0,$rebase1,$rebase2,$rebase3)
{
    background-color:$rebase03;
    color:$rebase0;
    * { color:$rebase0; }
    h1,h2,h3,h4,h5,h6 { color:$rebase1; border-color: $rebase0; }
    a, a:active, a:visited { color: $rebase1; }
}

@mixin accentize($accent) {
    a, a:active, a:visited, code.url { color: $accent; }
    h1,h2,h3,h4,h5,h6 {color:$accent}
}

// end copied section

/* Define colors for org-htmlize export */
pre.src {
    @include rebase($base3, $base2, $base1, $base0, $base00, $base01, $base02, $base03);

    // font lock
    .org-function-name { color: $blue;   }
    .org-variable-name { color: $blue;   }
    .org-preprocessor  { color: $blue;   }
    .org-string        { color: $cyan;   }
    .org-type          { color: $yellow; }
    .org-builtin {
        color: $base00;
        font-style: italic;
        font-weight: bolder;
    }
    .org-comment {
        color: $base1;
    }
    .org-comment-delimiter {
        font-style: italic;
        color: $base1;
    }
    .org-constant {
        color: $blue;
        font-weight: bolder;
    }
    .org-doc {
        color: $violet;
        font-style: italic;
    }
    .org-keyword {
        color: $green;
        font-weight: bolder;
    }
    .org-negation-char {
        color: $yellow;
        font-weight: bolder;
    }
    .org-regexp-grouping-construct {
        color: $yellow;
        font-weight: bolder;
    }
    .org-regexp-grouping-backslash {
        color: $green;
        font-weight: bolder;
    }
    .org-error, .org-warning { color: $orange; }

    // rainbow delimiters
    .org-rainbow-delimiters-depth-1  { color: $cyan;   }
    .org-rainbow-delimiters-depth-2  { color: $yellow; }
    .org-rainbow-delimiters-depth-3  { color: $blue;   }
    .org-rainbow-delimiters-depth-4  { color: $violet; }
    .org-rainbow-delimiters-depth-5  { color: $green;  }
    .org-rainbow-delimiters-depth-6  { color: $yellow; }
    .org-rainbow-delimiters-depth-7  { color: $blue;   }
    .org-rainbow-delimiters-depth-8  { color: $violet; }
    .org-rainbow-delimiters-depth-9  { color: $green;  }
    .org-rainbow-delimiters-depth-10 { color: $yellow; }
    .org-rainbow-delimiters-depth-11 { color: $blue;   }
    .org-rainbow-delimiters-depth-12 { color: $violet; }
    .org-rainbow-delimiters-unmatched {
        color: $base00;
        background-color: $base3;
        filter: invert(100%);
    }

    // highlight-numbers
    .org-highlight-numbers-number {
        color: $violet;
        font-weight: normal;
    }

    // perl-mode
    .org-cperl-array { color: $blue; }
    .org-cperl-hash  { color: $blue; }
    .org-cperl-nonoverridable { color: $base00; font-weight: bolder; }

    // sh-mode
    .org-sh-quoted-exec { color: $violet; font-weight: bolder; }
    .org-sh-escaped-newline,
    .org-sh-heredoc     { color: $yellow; font-weight: bolder; }

    // tuareg-mode
    .org-tuareg-font-lock-governing {
        color: $magenta;
        font-weight: bolder;
    }
    .org-tuareg-font-lock-multistage {
        color: $blue;
        background-color: $base2;
        font-weight: bolder;
    }
    .org-tuareg-font-lock-operator { color: $base01; }
    .org-tuareg-font-lock-error {
        color: $yellow;
        background-color: $red;
        font-weight: bolder;
    }
    .org-tuareg-font-lock-interactive-output { color: $cyan; }
    .org-tuareg-font-lock-interactive-error  { color: $red;  }

    // manual inspection
    .org-tuareg-font-double-colon {
        color: #ff5d00;
    }
    .org-tuareg-font-lock-module {
        @extend .org-type;
    }
    .org-tuareg-font-lock-constructor {
        /* deliberately empty */
    }
    .org-tuareg-font-lock-label {
        @extend .org-constant;
    }
    .org-tuareg-font-lock-extension-node {
        @extend .org-preprocessor;
    }

    // de-emphasize line numbers
    .linenr {
        @extend .org-comment;
    }
}

@import 'minima';

$font-family-monospace: Iosevka, Menlo, Monaco, Consolas, "Courier New", monospace;

@import 'bootstrap-custom';

// Code color scheme
@import 'solarized';

// Web fonts
@import 'iosevka';

pre,
code,
kbd,
samp {
    font-family: $font-family-monospace;
    @include font-size(1em); // Correct the odd `em` font sizing in all browsers.
}

pre {
    padding: 1rem;
    line-height: 1.4;
    white-space: pre;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
    code {
        padding: 0;
        font-size: 100%;
        color: inherit;
        background-color: transparent;
    }
}

// Emoji alignment
img.emoji {
    display: inline;
    margin: 0;
}

// Video embeds
video {
    width: 100%;
}

dt {
    font-weight: bolder;
}
